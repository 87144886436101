import React from "react"

// import vue from "../../assets/icons/vue.png"

import "./TechIcon.sass"

const icons = {
  vue: require("../../assets/icons/vue.png"),
  bulma: require("../../assets/icons/bulma.png"),
  firebase: require("../../assets/icons/firebase.png")
}

const TechIcon = props => {
  // let icon = this
  // console.log(window)
  return (
    <div className="tech-icon">
      <img src={icons[props.icon]} alt={`Icon ${props.name}`} />
      <p>{props.name}</p>
    </div>
  )
}

export default TechIcon
