import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faLinkedin,
  faGithub,
  faTwitter
} from "@fortawesome/free-brands-svg-icons"

import "./ProfilePage.sass"

export default class ProfilePage extends Component {
  render() {
    return (
      <div className="profile">
        <div className="profile__wrap">
          {/* <div className="profile-pic">
            <img src="https://bit.ly/372J5DP" alt="pic"/>
          </div> */}
          <div className="side__bar"></div>
          <div className="dashed__bar"></div>
          <div className="solid__bar"></div>
          <div className="bottom__bar">
            <h5>dev.rgllima@gmail.com</h5>
            <div className="bar"></div>
          </div>
          <div className="profile__description">
            <div className="profile__description__title">
              <h5>Frontend / Developer</h5>
              <h1>Rafael Lima</h1>
              <div className="bar"></div>
            </div>

            <div className="profile__description__contacts">
              <a
                href="https://fb.me/rgllima"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="https://linkedin.com/in/rgllima"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a
                href="https://github.com/rgllima"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a
                href="https://twitter.com/rgllima"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </div>

            <div className="profile__description__summary">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.{" "}
              </p>
            </div>

            <div className="profile__description__actions">
              <button className="button">VIEW MY CV</button>
              <div className="ball"></div>
            </div>
          </div>
          <div className="profile__skills">
            <div className="profile__skills__title">
              <h1>PROFILE</h1>
            </div>
            <div className="profile__skills__wrap">
              {/* <div className="extra">

              </div> */}
              <div className="improving">
                <h4>Improving</h4>
                <h3>English</h3>
                <h3>Flutter</h3>
                <h3>Mobile Dev.</h3>
              </div>
              <div className="skills">
                <h4>Skills</h4>
                <h3>UI/UX</h3>
                <h3>Layout Design</h3>
                <h3>Responsive Design</h3>
                <h3>Soft. Engineering</h3>
                <h3>Version Control/Git</h3>
                <h3>Testing/Debugging</h3>
                <h3>Dev/Ops</h3>
                <h3>Browser Dev. Tools</h3>
                <h3>Command Line</h3>
              </div>
              <div className="languages">
                <h4>Technologies</h4>
                <h3>VueJS</h3>
                <h3>NodeJs</h3>
                <h3>ReactJs</h3>
                <h3>JQuery</h3>
                <h3>MongoDB</h3>
                <h3>Firebase</h3>
                <h3>Heroku</h3>
                <h3>Sass</h3>
                <h3>Bulma</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
