import React, { Component } from "react"
import { Link } from "react-router-dom"
import "./NavBar.sass"

export default class NavBar extends Component {
  render() {
    return (
      <div className="navbar">
        <div className="navbar__left">
          <Link className="navbar__item" to="/">
            <h1>rgllima</h1>
          </Link>
        </div>
        <div className="navbar__right">
          <Link className="navbar__item" to="/profile">
            <h4>Profile</h4>
          </Link>
          {/* <Link className="navbar__item" to="/profile">
            <h4>About</h4>
          </Link> */}
          <Link className="navbar__item" to="/cases">
            <h4>Case Study</h4>
          </Link>
          <Link className="navbar__item" to="/blog">
            <h4>Blog</h4>
          </Link>
          <Link className="navbar__item" to="/contact">
            <h4>Contact</h4>
          </Link>
        </div>
      </div>
    )
  }
}
