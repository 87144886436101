import React, { Component } from "react"
import Tag from "../Tag/Tag"
import TechIcon from "../TechIcon/TechIcon"
import SimpleSlider from "../Slider/SimpleSlider"

import cover from "../../assets/cover.jpg"
import cover2 from "../../assets/cover2.png"
import cover3 from "../../assets/dribbble.png"

import "./CaseCard.sass"

export default class CaseCard extends Component {
  render() {
    return (
      <div className="case-card">
        <div className="case-card__left">
          <div className="case-card__title">
            <h1>Lorem Ipsum is simply dummy</h1>
          </div>

          <div className="case-card__tags">
            <Tag title="Vue" />
            <Tag title="Bulma" />
          </div>

          <div className="case-card__cover">
            {/* <img src={cover} alt="cover" /> */}
            <SimpleSlider images={[cover, cover2, cover3]} />
          </div>
        </div>
        <div className="case-card__right">
          <div className="case-card__description">
            <h3>Description</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.{" "}
            </p>

            <h3>Main Challenges</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.{" "}
            </p>
          </div>

          <div className="case-card__technologies">
            <h3>Technologies</h3>
            <div className="tech-wrap">
              <TechIcon name="VueJs" icon="vue" />
              <TechIcon name="Bulma.io" icon="bulma" />
              <TechIcon name="Firebase" icon="firebase" />
              {/* <TechIcon name="Bulma.io" icon="bulma" />
              <TechIcon name="VueJs" icon="vue" /> */}
            </div>
          </div>

          <div className="case-card__skills">
            <h3>Skills</h3>
            <ul>
              <li>Responsive Layout</li>
              <li>UI/UX</li>
              <li>Testing</li>
              <li>Version Control</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
