import React, { Component } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import "./SimpleSlider.sass"

export default class SimpleSlider extends Component {
  render() {
    let a = this.props.images.map((img, idx) => (
      <img src={img} alt={`img-${idx}`} key={idx}></img>
    ))

    return (
      <Carousel useKeyboardArrows emulateTouch>
        {a}
      </Carousel>
    )
  }
}
