import React, { Component } from "react"
import { Switch, Route } from "react-router-dom"

import NavBar from "./NavBar/NavBar"
import HomePage from "../../views/HomePage/HomePage"
import ProfilePage from "../../views/ProfilePage/ProfilePage"
import CaseStudy from "../../views/CaseStudy/CaseStudy"
import BlogPage from "../../views/BlogPage/BlogPage"

import "./Default.sass"

export default class DefaultLayout extends Component {
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <div className="default-layout">
          <Switch>
            <Route path="/" exact={true} component={HomePage} />
            <Route path="/profile" component={ProfilePage} />
            <Route path="/cases" component={CaseStudy} />
            <Route path="/blog" component={BlogPage} />
            <Route component={HomePage} />
          </Switch>
        </div>
      </React.Fragment>
    )
  }
}
