import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faLinkedin,
  faGithub,
  faTwitter
} from "@fortawesome/free-brands-svg-icons"

import "./ContactsSidebar.sass"

const ContactsSidebar = () => {
  return (
    <div className="contacts-sidebar">
      <div className="bar"></div>
      <a href="https://fb.me/rgllima" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebookF} />
      </a>
      <a
        href="https://linkedin.com/in/rgllima"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a
        href="https://github.com/rgllima"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faGithub} />
      </a>
      <a
        href="https://twitter.com/rgllima"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faTwitter} />
      </a>
    </div>
  )
}

export default ContactsSidebar
