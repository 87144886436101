import React from "react"
import DefaultLayout from "./layout/Default"

import "./assets/css/_general.sass"

function App() {
  return (
    <React.Fragment>
      <div id="app">
        <DefaultLayout />
      </div>
    </React.Fragment>
  )
}

export default App
