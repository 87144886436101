import React, { Component } from "react"
import ComingSoon from "../../assets/coming-soon.png"

import "./BlogPage.sass"

export default class BlogPage extends Component {
  render() {
    return (
      <div className="blog-page">
        <h1>Coming Soon</h1>
        <a href="https://icons8.com" target="_blank" rel="noopener noreferrer">
          <img src={ComingSoon} alt="Coming Soon" />
        </a>
      </div>
    )
  }
}
