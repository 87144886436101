import React from "react"
import "./Tag.sass"

const Tag = props => {
  // const buttonClass = `button${props.type ? "--" + props.type : ""} ${props.className}`

  return (
    // <button className={buttonClass} onClick={props.onClick}>
    //   {props.children}
    // </button>
    <div className="tag">{props.title.toUpperCase()}</div>
  )
}

export default Tag
