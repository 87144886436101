import React, { Component } from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookF,
  faLinkedin,
  faGithub,
  faTwitter
} from "@fortawesome/free-brands-svg-icons"
import clipImg from "../../assets/clip-education.png"
import "./HomePage.sass"

class Homepage extends Component {
  render() {
    return (
      <div className="homepage">
        <div className="intro">
          <div className="intro__contacts">
            <div className="bar"></div>
            <a
              href="https://fb.me/rgllima"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://linkedin.com/in/rgllima"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a
              href="https://github.com/rgllima"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
            <a
              href="https://twitter.com/rgllima"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </div>
          <div className="intro__summary">
            <div className="intro__summary__about">
              <h3>Rafael Lima</h3>
              <h5>Frontend Developer</h5>
              <div className="bar"></div>
            </div>

            <div className="intro__summary__quotes">
              <h1>
                “When you don't create things, you become defined by your tastes
                rather than ability. Your tastes only narrow and exclude people.
                So create.”
              </h1>
              <h5>Why The Lucky Stiff</h5>

              <div className="intro__summary__actions">
                <Link to="/profile">
                  <h3>View Profile</h3>
                </Link>

                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
          </div>
        </div>
        <div className="homepage__creative">
          <img src={clipImg} alt="clip" />
        </div>
      </div>
    )
  }
}

export default Homepage
