import React, { Component } from "react"
import CaseCard from "../../components/CaseCard/CaseCard"
import ContactsSidebar from "../../components/ContactsSidebar/ContactsSidebar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Switch, Route, withRouter, Link, Redirect } from "react-router-dom"

import "./CaseStudy.sass"

const cases = [
  { slug: "viso-dashboard", component: <CaseCard /> },
  { slug: "pagina-social", component: <CaseCard /> },
  { slug: "pagina-antisocial", component: <CaseCard /> }
]
class CaseStudy extends Component {
  _prevPathName = ""
  _nextPathName = ""

  async findPrevNextPaths() {
    let path = this.props.location.pathname.split("/cases/")[1]
    console.log(path)

    let elem = cases.filter(a => a.slug === path)[0]
    let index = cases.indexOf(elem)
    let prevIndex = index - 1
    let nextIndex = index + 1

    this._prevPathName = prevIndex < 0 ? null : cases[prevIndex].slug
    this._nextPathName =
      nextIndex >= cases.length ? null : cases[nextIndex].slug
  }

  render() {
    console.log(this.props)
    console.log(this._prevPathName, this._nextPathName)
    let { location } = this.props
    console.log(location)
    this.findPrevNextPaths()

    let routes = cases.map((comp, idx) => (
      <Route
        key={idx}
        path={`/cases/${comp.slug}`}
        component={() => comp.component}
      />
    ))

    // Push default route
    // routes.push(
    //   <Route key={cases.length} component={() => cases[0].component} />
    // )

    return (
      <div className="case-study">
        <ContactsSidebar />

        <div className="case-study__content">
          <div className="case-study__content__header">
            <h1>CASES</h1>

            <span>
              {this._prevPathName ? (
                <span className="case-study__content--pointer">
                  <Link to={`/cases/${this._prevPathName}`}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Previous Case
                  </Link>
                </span>
              ) : null}

              {this._prevPathName && this._nextPathName ? (
                <span>{` | `}</span>
              ) : null}

              {this._nextPathName ? (
                <span className="case-study__content--pointer">
                  <Link to={`/cases/${this._nextPathName}`}>
                    Next Case <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </span>
              ) : null}
            </span>
          </div>

          <TransitionGroup className="transition-group">
            <CSSTransition
              key={location.key}
              timeout={{ enter: 500, exit: 500 }}
              classNames="fade"
            >
              <Switch location={location}>
                {routes}
                <Redirect to={`/cases/${cases[0].slug}`} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    )
  }
}

export default withRouter(CaseStudy)
